<template>
  <ValidationObserver :key="formKey" v-slot="{ handleSubmit }">
    <form class="newsletter-form" @submit.prevent="handleSubmit(submitForm)">
      <div class="newsletter-form__input">
        <CustomInput
          v-model="form.email"
          input-label="Email"
          input-name="newsletter-input"
          input-id="newsletter-input"
          input-type="email"
          validate-rule="email"
        />
        <nuxt-img
          :src="
            formIsSubmittable
              ? '/icons/vectors/arrow-next.svg'
              : '/icons/vectors/arrow-next-grey.svg'
          "
          alt="submit"
          class="newsletter-form__input__icon"
          @click="handleSubmit(submitForm)"
          :class="
            !formIsSubmittable && 'newsletter-form__input__icon__disabled'
          "
        />
      </div>
      <div class="newsletter-form__checkbox">
        <CustomCheckbox v-model="form.checkbox" validate-rule="required" />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { useI18n } from '~/helpers/hooks/usei18n';
import { useGtm, useUiNotification } from '~/composables';
import { ValidationObserver } from 'vee-validate';
import { ref, defineComponent, computed } from '@nuxtjs/composition-api';
import { useNewsletter, useUser } from '@gemini-vsf/composables';
import { CustomInput, CustomCheckbox } from '~/components/General';

export default defineComponent({
  name: 'NewsletterForm',
  components: {
    CustomInput,
    CustomCheckbox,
    ValidationObserver,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { load } = useUser();
    const translate = useI18n();
    const { send: sendNotification } = useUiNotification();
    const {
      subscribeEmailToNewsletterStatus,
      updateSubscription: newsletterSubscribe,
      error: newsletterError,
    } = useNewsletter();

    const form = ref({});

    const formIsSubmittable = computed(
      () => form.value && form.value.checkbox && form.value.email
    );
    const { formActionGtmPush } = useGtm();

    const submitForm = async () => {
      await load();
      await newsletterSubscribe({
        email: form.value.email,
        name: '',
        lastName: '',
      });
      const notification = {
        id: Symbol('subscribe_error'),
        message: `${translate.t(
          'You are already subscribed to the newsletter. for support contact us at info@sognidicristallo.it'
        )}`,
        type: 'danger',
        persist: false,
        title: translate.t('Subscription Error'),
      };
      if (subscribeEmailToNewsletterStatus.value === undefined) {
        sendNotification(notification);
        formActionGtmPush(
          `GA4-EVENT-${props.formKey.replace('-', ' ').toUpperCase()}-HEVELOP`,
          true
        );
      } else if (
        newsletterError?.value?.updateSubscription ||
        subscribeEmailToNewsletterStatus.value !== 'SUBSCRIBED'
      ) {
        notification.message = `${translate.t(
          "Sorry, we couldn't subscribe you to our newsletter. Please contact us at info@sognidicristallo.it"
        )}`;
        sendNotification(notification);
        formActionGtmPush(
          `GA4-EVENT-${props.formKey.replace('-', ' ').toUpperCase()}-HEVELOP`,
          true
        );
      } else {
        notification.id = Symbol('subscribe_success');
        notification.message = `${translate.t(
          'Your subscription was successfull!'
        )}`;
        notification.type = 'success';
        notification.title = translate.t('Subscription Success');
        sendNotification(notification);
        emit('success');
        form.value = {};
        formActionGtmPush(
          `GA4-EVENT-${props.formKey.replace('-', ' ').toUpperCase()}-HEVELOP`,
          false
        );
      }
    };

    return {
      form,
      submitForm,
      formIsSubmittable,
    };
  },
});
</script>

<style lang="scss" scoped>
@keyframes rotateAndScale {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(2);
  }
  100% {
    transform: rotate(359deg) scale(1);
  }
}
.newsletter-form {
  &__input {
    padding-top: 1.25rem;
    --input-width: 100%;
    position: relative;
    &__icon {
      position: absolute;
      top: 2.1875rem;
      right: 1.5625rem;
      @include pointer;
      &:not(&__disabled) {
        animation: rotateAndScale 1s 2 linear;
      }
      &__disabled {
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
  &__checkbox {
    padding-top: 1.25rem;
  }
}
</style>
