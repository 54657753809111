<template>
  <transition-group tag="div" class="notifications" name="slide-fade">
    <NotificationCustom
      v-for="notification in notifications"
      :key="notification.id"
      :message="notification.message"
      :action="notification.action && notification.action.text"
      :type="notification.type"
      visible
      @click:close="notification.dismiss"
      @click:action="
        (e) =>
          notification.action &&
          notification.action.onClick.call(null, e, notification)
      "
    >
      <template v-if="notification.icon" #icon>
        <SfIcon :icon="notification.icon" color="black" />
      </template>
    </NotificationCustom>
  </transition-group>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import {
  // SfNotification,
  SfIcon,
} from '@storefront-ui/vue';
import { useUiNotification } from '~/composables';
import NotificationCustom from '~/components/NotificationCustom.vue';

export default defineComponent({
  name: 'Notification',
  components: {
    // SfNotification,
    SfIcon,
    NotificationCustom,
  },
  setup() {
    const { notifications } = useUiNotification();

    return {
      notifications,
    };
  },
});
</script>

<style scoped lang="scss">
.notifications {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  @include to-tablet-max {
    z-index: 999999999999;
  }
  @include from-desktop-min {
    left: unset;
    right: var(--spacer-base);
    bottom: var(--spacer-base);
    width: 25rem;
  }
}

.sf-notification {
  max-width: 100%;
  --notification-background: var(--c-white);
  --notification-color: var(--c-black);
  &:last-child {
    margin: 0;
  }

  &.color-danger {
    --notification-background: var(--c-gold);
  }
  &.color-success {
    border: 1px solid var(--c-black);
    @include to-tablet-max {
      border: none;
      border-top: 1px solid var(--c-black);
    }
  }

  &:first-child {
    margin-top: 0;
  }

  @include for-mobile {
    --notification-border-radius: 0;
    --notification-max-width: 100%;
    --notification-font-size: var(--font-size--sm);
    --notification-font-family: var(--font-family--primary);
    --notification-font-weight: var(--font-weight--normal);
    --notification-padding: var(--spacer-base) var(--spacer-lg);
  }
  @include for-desktop {
    margin: 0 0 var(--spacer-base) 0;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s;
  @include for-desktop {
    transition: opacity 0.25s linear;
  }
}

.slide-fade-enter {
  transform: translateY(40px);
  @include for-desktop {
    opacity: 0;
  }
}

.slide-fade-leave-to {
  transform: translateY(80px);
  @include for-desktop {
    opacity: 0;
  }
}
</style>

<style lang="scss">
.notifications {
  .sf-notification {
    display: block;
    padding: var(--spacer-lg);

    @include to-tablet-max {
      padding: 1.875rem;
    }

    &__message {
      @include paragraph-m;
    }

    .custom-button {
      margin: auto;
      margin-top: 1.875rem;
      &.cta-close-notification {
        border-color: var(--c-black);
        min-width: 100%;

        &.color-danger {
          color: var(--c-gold);

          &:hover {
            color: var(--c-black);
          }
        }
      }
    }
  }
}
</style>
